import React from "react";

import { BsCaretRight } from "react-icons/bs";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { MdPhoneInTalk, MdPlayArrow } from "react-icons/md";
import { FaUserAlt } from "react-icons/fa";

export const icons = {
  right: <BsCaretRight />,
  whatsapp: <AiOutlineWhatsApp />,
  phone: <MdPhoneInTalk />,
  user: <FaUserAlt />,
  playarrow: <MdPlayArrow />,
};
