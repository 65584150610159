import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import Retail from "@components/Inburg/Retail";

const RequestAProposal = ({ item }) => {
  const {isOpen,onClose,heading}=item
  return (
    <>
      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent className="!max-w-[500px] w-full">
          <ModalHeader>
            <div className="mr-2">
              <div className="flex justify-center items-start">
                <img
                  className="w-[70px] invert mt-1 lg:flex hidden"
                  src="/images/inburg-logo.png"
                  alt=""
                />
                <h3 className="text-[24px] text-center">
                  <span className="text-yellow-light text-[24px]">Request</span>{" "}
                  sponsorship <br /> proposal
                </h3>
              </div>
            </div>
          </ModalHeader>
          <ModalCloseButton onClick={onClose} />
          <ModalBody className="px-6">
            <h5 className="text-center lg:!text-[18px] !text-[14px]">
              {heading}
            </h5>

            <form action="" className="px-4 py-6 flex flex-col gap-4">
              <div className="flex flex-col gap-1">
                <label htmlFor="" className="font-semibold text-sm">
                  Company Name <sup>*</sup>
                </label>
                <input type="text" placeholder="Enter company name" />
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="" className="font-semibold text-sm">
                  Website <sup>*</sup>
                </label>
                <input type="text" placeholder="Enter website name" />
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="" className="font-semibold text-sm">
                  Contact No <sup>*</sup>
                </label>
                <input type="text" placeholder="Enter your contact" />
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="" className="font-semibold text-sm">
                  Email ID <sup>*</sup>
                </label>
                <input type="email" placeholder="Enter your email" />
              </div>
              <div className="flex justify-center">
                <button className="btn bg-red-500 text-white">Submit</button>
              </div>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RequestAProposal;
