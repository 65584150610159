import { useDisclosure } from "@chakra-ui/react";
import Dots from "@components/Inburg/Dots";
import HorizontalLine from "@components/Inburg/HorizontalLine";
import Private from "@components/Inburg/Private";
import Retail from "@components/Inburg/Retail";
import RetailDelhi from "@components/Inburg/RetailDelhi";
import RequestAProposal from "@components/popup/RequestAProposal";
import { agenda, home } from "@utils/constants";
import { icons } from "@utils/icons";

import React, { useState } from "react";
const HomePage = () => {
  const[heading,setHeading]=useState('');
  const[isOpen,setOnOpen]=useState(false);
  const onClose=()=>{
    setOnOpen(false);
  }


  
  return (
   <>
      <div className="bg-black text-white">
      {/* Section-1*/}
      <section className="container pt-[50px]">
        <div className="flex justify-end items-center gap-10">
          <a href='#reserve-seat'  rel="noopener noreferrer" className="uppercase text-white request-btn  rounded-full">
            Reserve your seat
          </a>{" "}
          <span>
            {" "}
            <Dots />
          </span>
        </div>
        <div className="flex tablet:flex-row flex-col lg:gap-[100px] gap-[50px] lg:pt-[50px] pt-[30px]">
          <div className="lg:w-[80%] tablet:w-[60%] w-full">
            <div className="flex gap-4 items-center">
              <img
                className="lg:w-[250px] w-[170px]"
                src="/images/inburg-logo.png"
                alt=""
              />
              <img
                className="w-[100px] lg:w-[180px] ml-3"
                src="/images/gradient-img.png"
                alt=""
              />
            </div>
            <h1 className="text-yellow-light lg:pt-[20px] lg:text-[98px] text-[35px] font-bold font-inter">
              RETAIL
            </h1>
            <h1 className=" lg:text-[98px] text-[35px] font-bold font-inter">
              ECOSYSTEM INDIA
            </h1>
            <div className="flex lg:flex-row flex-col lg:space-x-[100px] lg:items-center pt-6">
              <h2 className="">Outlook2023</h2>
              <h3 className="">Podcasts . Summit</h3>
            </div>
          </div>
          <div className="center tablet:justify-end tablet:items-end  flex-col">
            <div className="rounded_img">
              <img className="hwro_full" src="/images/mic.png" alt="" />
            </div>
            <div className="rounded_img mt-6">
              <img
                className="bottom-[20px] hwro_full"
                src="/images/project.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="lg:pt-20 pt-10">
          <Dots />
        </div>
      </section>

      {/* Section-2*/}
      <section className="pt-[50px] container">
        <div className="flex lg:flex-row flex-col lg:justify-between">
          {" "}
          <Retail />
          <span className="lg:pt-0 pt-4">
            {" "}
            <Dots />
          </span>
        </div>
        <div className="flex lg:flex-row flex-col gap-x-[40px]">
          <div className="lg:max-w-[50%] w-full lg:pt-[50px] pt-[40px]">
            <h5 className="text_horizontal_line">Concept Note</h5>
            <p className="pt-[20px] text-gray-light lg:text-[18px] text-[14px]">
              India’s retail industry is projected to grow more than US$ 1.8
              trillion by 2030. This needs strategic initiatives and support
              from all the verticals and stakeholders in the Industry. <br />{" "}
              <br /> In a series of events (podcasts, research report, and
              in-person event) we are going to discuss challenges, opportunities
              and the way forward to make the system a winning ecosystem.
              <br />
              <br /> This is an excellent opportunity for the participating
              sponsors to lead the talks, get brand visibility, and reach
              potential business prospects through speaking opportunities in
              podcasts, brand mentions throughout the formats and networking
              opportunities with decision makers of hundreds of retail
              stakeholders.
            </p>
          </div>
          <div className="flex lg:justify-end lg:items-end lg:pt-0 tablet:pt-6 w-full">
            <div className="lg:max-w-[400px] w-full">
              <img
                className=" w-full h-full object-cover lg:mt-0 mt-4"
                src="/images/watch.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="mt-[50px]">
          <Dots />
        </div>
      </section>

      {/* Section-3*/}
      <section className="pt-[50px] container">
        <div className="flex lg:flex-row flex-col lg:justify-between">
          {" "}
          <Retail />
          <span className="lg:pt-0 pt-4">
            {" "}
            <Dots />
          </span>
        </div>
        <div className="">
          <h5 className="text_horizontal_line lg:mt-10 mt-6">
            Sequence of events
          </h5>
          {/* <span className=" bg-yellow-dark w-[500px] h-[5px]"></span> */}
          <div className="flex tablet:flex-row flex-col pt-[50px]">
            <div className="w-full flex justify-center">
              <div>
                <div className="rounded_img2">
                  <img className="hwro_full" src="/images/mic.png" alt="" />
                </div>
                <ul className="text-[15px] pt-[30px]">
                  <li className=" text-yellow-light text-[18px]">
                    Virtual Podcast
                  </li>
                  <li className="pt-6 pb-[10px] text-gray-light leading-6 font-medium">
                    Nov 30, 2022 - <br /> Jan 10, 20223
                  </li>
                  <br />
                  <li className="text-gray-light leading-6">
                    10 Episodes
                    <br /> 10 Guests
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-full flex justify-center">
              <div className="tablet:pt-0 pt-10 flex flex-col tablet:items-start items-center">
                <div className="rounded_img2">
                  <img className="hwro_full" src="/images/project.jpg" alt="" />
                </div>
                <ul className="text-[15px] text-gray-light pt-[30px]">
                  <li className="text-yellow-light lg:text-[19px] text-[14px] font-semibold">
                    {" "}
                    Retail Ecosystem India Summit 2023
                  </li>
                  <li className="text-end lg:text-[17px] text-[14px] text-white">
                    DELHI CHAPTER
                  </li>
                  <li className="text-gray-light font-medium">
                    Jan 17, 2023 <br />{" "}
                    <span className="text-yellow-light text-[12px]">
                      Crowne Plaza, Okhla, New Delhi
                    </span>
                  </li>
                  <li className="pt-[30px] leading-6">
                    125+ Delegates* <br /> 25+{" "}
                    <span className="pl-2"> Speakers</span> <br /> 200+
                    Attendees
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <p className="text-[12px] pt-10 text-gray-light">
            *Delegates - C-Suite leaders of popular retail chains, Policy
            makers, Media professionals, Angels & VCs
          </p>
          <div className="mt-[50px]">
            <Dots />
          </div>
        </div>
      </section>
      {/* Section-4*/}

      <section className="mt-[50px] container">
        <div className="flex justify-end items-center gap-10">
          <a href="#reserve-seat" className="uppercase text-white request-btn rounded-full  ">
            Reserve your seat
          </a>{" "}
          <span>
            {" "}
            <Dots />
          </span>
        </div>
        <div className="pt-[30px]">
          <h5 className=" text_horizontal_line mb-4">Agenda</h5>
          <div className="grid lg:grid-cols-2 gap-2 grid-flow-row lg:grid-rows-6  pt-[10px] mx-auto">
            {agenda.map((item, index) => (
              <div className="flex flex-col gap-2 overflow-x-hidden">
                <div className="flex items-start gap-4 w-full">
                  <img
                    className="w-[25px] mt-1"
                    src="/images/clock2.png"
                    alt=""
                  />
                  <div>
                    <h6 className="text-gray-light font-semibold text-[15px] ">
                      {item.time}
                      <span className="text-yellow-dark pl-4 font-normal">
                        {item.event}
                      </span>
                    </h6>

                    {item.para && (
                      <div className="flex items-center mt-1">
                        <div className="text-yellow-light text-[20px]">
                          {icons.playarrow}
                        </div>
                        <p className="text-[12px] text-gray-light">
                          {item.para}
                        </p>
                      </div>
                    )}
                  </div>
                </div>

                <ul className="list-disc flex gap-3 ml-2 w-full max-w-[340px] tablet:max-w-full overflow-hidden">
                  {Array(38)
                    .fill("2")
                    .map((item, index) => (
                      <li className="[&::marker]:text-gray-500"></li>
                    ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
        <div className="pt-[30px]">
          <Dots />
        </div>
      </section>

      {/* Section-5 */}
      <section className="pt-[50px] container">
        <div className="flex justify-end">
          {" "}
          <Dots />
        </div>
        <h5 className="text_horizontal_line lg:mt-[50px] mt-6">
          125+ Brands attending the Summit
        </h5>
        <span className="icon-line"></span>
        <div className="flex flex-wrap mt-[50px] gap-10 place-items-center">
          {home.map((item, index) => (
            <div>
              <img className="h-10" src={item.imgpath} alt="" />
            </div>
          ))}
        </div>
        <h6 className="py-10 text-[19px]">and more..</h6>
        <div className="mt-[50px]">
          <Dots />
        </div>
      </section>

      {/* Section-6 */}
      <section className="container pt-[50px]">
        <div className="flex justify-end">
          {" "}
          <Dots />
        </div>
        <div className="flex flex-col justify-end items-end lg:pt-[80px] pt-[50px] pb-[60px] w-full relative">
          <h5 className="text_horizontal_line absolute lg:right-[9%] right-[14%] font-inter">
            Proposition for Sponsors
          </h5>
        </div>
        <div className="center lg:flex-row flex-col lg:justify-between lg:items-start space-y-20 lg:space-y-0 max-w-[1300px] w-full mx-auto">
          <div className="relative">
            <div className="white-rounded">A</div>
            <div className="relative pt-[20px] lg:pb-[60px] pb-[40px] bg-yellow-light max-w-[300px] min-h-[340px] w-full  rounded-tr-[80px] rounded-bl-[80px] text-black linear-gray">
              <h6 className="absolute vertical_line whitespace-nowrap">
                EVENT PARTNERS
              </h6>
              <div className="lg:px-[60px]  px-[40px] lg:pr-5">
                <ul className="list-none space-y-[25px] card-text">
                  {[
                    "Logo on event page/website",
                    "One dedicated LinkedIn post announcing sponsorship",
                    "Brand mention in post-event article thanking sponsors",
                    "Complementary delegate pass - 1",
                  ].map((item, index) => (
                    <li className="leading-[22px] font-quicksand font-medium">
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="lg:right-[-4px] absolute lg:bottom-[-30px] right-[-9px] bottom-[-19px]">
                <button 
                onClick={()=>{
                  setHeading('EVENT PARTNERS');
                  setOnOpen(true);
                }}
               className="btn-btn-primary gap-0 lg:text-[23px] text-[18px] items-center">
                  Request a proposal
                  <span className="yellow-rounded"></span>
                </button>
              </div>
            </div>
          </div>
          <div className="relative">
            <div className="white-rounded">B</div>
            <div className="relative pb-[30px] pt-3 bg-yellow-light max-w-[400px] w-full  rounded-tr-[106px] rounded-bl-[106px] text-black linear-gray">
              <h1 className="absolute vertical_line whitespace-nowrap lg:top-[40px] top-[61px]">
                CO-POWERED BY
              </h1>
              <div className="lg:px-[70px] px-[41px] lg:pr-8">
                <h5 className="uppercase tracking-wide whitespace-nowrap mb-3 font-black !text-[18px]">
                  Everything in A, plus;
                </h5>
                <ul className="list-none card-text lg:space-y-[10px] text-[15px]">
                  {[
                    "Mentions in two pre-event LinkedIn posts",
                    "Mentions in two LinkedIn posts during events",
                    "Mentions in two LinkedIn posts after event",
                    "Logo on event agenda brochure",
                    "Complementary delegate pass  (2)",
                    "Speaking opportunity in a panel discussion",
                    "Corporate gift opportunity to speakers/moderators",
                  ].map((item, index) => (
                    <li className="leading-[22px] font-quicksand font-medium">
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="lg:right-0 absolute lg:bottom-[-40px] right-[-9px] bottom-[-26px]">
                <button
                 onClick={()=>{
                  setHeading('CO-POWERED BY');
                  setOnOpen(true);
                }}
                
                className="btn-btn-primary gap-0">
                  Request a proposal
                  <span className="yellow-rounded"></span>
                </button>
              </div>
            </div>
          </div>

          <div className="relative">
            <div className="white-rounded">C</div>
            <div className="relative pt-4 pb-[40px] bg-yellow-light max-w-[450px] w-full rounded-tr-[106px] rounded-bl-[106px] text-black linear-gray">
              <h1 className="absolute vertical_line whitespace-nowrap lg:top-[90px] top-[117px]">
                POWERED BY
              </h1>
              <div className="lg:px-[70px] lg:pr-6 px-[40px]">
                <h5 className="uppercase tracking-wide whitespace-nowrap mb-3 font-black !text-[18px]">
                  Everything in B, plus;
                </h5>
                <ul className="list-none card-text lg:space-y-[10px] space-y-[6px] text-[15px]">
                  {[
                    "Logo on event backdrop main panel",
                    "Logo on Interior & Exterior signage",
                    "Logo in event invite emails",
                    "Logo in thank you email to attendes",
                    "25 minutes fire-side chat ",
                    "Special announcement thanking Partners",
                    "Complementary delegate pass (3)",
                    "Delegate coordinates (Database)",
                    "Complementary exclusive invite as speaker/guest  in a brand-focused podcast episode ",
                    "Brand mention in pre & post podcast LinkedIn posts",
                  ].map((item, index) => (
                    <li className="leading-[22px] font-quicksand font-medium">
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="lg:right-0 right-[-9px] absolute lg:bottom-[-30px]  bottom-[-21px]">
                <button
                 onClick={()=>{
                  setHeading('POWERED BY');
                  setOnOpen(true);
                }}
                 className="btn-btn-primary ">
                  Request a proposal
                  <span className="yellow-rounded"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <Dots />
      </section>
      {/* Section-7*/}
      <section className="container pt-10">
        <div className="center lg:flex-row flex-col lg:justify-between max-w-[1200px] w-full mx-auto">
          <div className="relative lg:pb-0 pb-20">
            <div className="white-rounded">D</div>
            <div className="relative lg:pb-[100px] pb-[40px] pt-3 bg-yellow-light max-w-[400px] w-full  rounded-tr-[106px] rounded-bl-[106px] text-black linear-gray">
              <h1 className="absolute vertical_line whitespace-nowrap lg:top-[20px] top-[53px]">
                BROUGHT TO YOU BY
              </h1>
              <div className="lg:px-[70px] px-[40px]">
                <h5 className="uppercase tracking-wide whitespace-nowrap mb-3 font-black !text-[18px]">
                  Everything in C, plus;
                </h5>
                <ul className="list-none card-text lg:space-y-[10px] space-y-3 text-[15px] ">
                  {[
                    "Logo on social media covers",
                    "Logo on table tent cards",
                    "Logo on pre-event advertising banners",
                    "Logo on event registration desk",
                    "Partner's bite in event after movie (30 seconds)",
                    "25 minutes keynote address",
                    "Complementary delegate pass (4)",
                  ].map((item, index) => (
                    <li className="leading-[22px] font-quicksand font-medium">
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="right-0 absolute bottom-[-20px]">
                <button
                 onClick={()=>{
                  setHeading('BROUGHT TO YOU BY');
                  setOnOpen(true);
                }}
                 className="btn-btn-primary">
                  Request a proposal
                  <span className="yellow-rounded"></span>
                </button>
              </div>
            </div>
          </div>
          <div className="relative">
            <div className="white-rounded">E</div>
            <div className="relative tablet:pb-[70px] pb-[50px] pt-3 bg-yellow-light max-w-[400px] w-full  rounded-tr-[106px] rounded-bl-[106px] text-black linear-gradient">
              <h1 className="absolute vertical_line whitespace-nowrap lg:top-[30px] tablet:top-[6px] top:[58px]">
                IN ASSOCIATION WITH
              </h1>
              <div className="lg:px-[70px] lg:pr-4 px-[41px] ">
                <h5 className="uppercase tracking-wide whitespace-nowrap mb-3 font-black !text-[18px]">
                  Everything in D, plus;
                </h5>
                <ul className="list-none card-text lg:space-y-[10px] space-y-[6px] text-[15px] ">
                  {[
                    "Logo on event passes & attendees ribbons",
                    "Brand mentions in pre & post event press releases",
                    "Logo on memento for speakers / moderators",
                    "Logo on podium masking",
                    "Logo on Goody bags",
                    "Corporate film Screening (3 mins during lunch) ",
                    "Complementary delegate pass (6)",
                  ].map((item, index) => (
                    <li className="leading-[22px] font-quicksand font-medium">
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="right-0 absolute bottom-[-20px]">
                <button
                onClick={()=>{
                  setHeading('IN ASSOCIATION WITH');
                  setOnOpen(true);
                }}
                 className="btn-btn-primary">
                  Request a proposal
                  <span className="yellow-rounded"></span>
                </button>
              </div>
            </div>
          </div>
          <div className="relative max-w-[300px] w-full mt-20">
            <div className="">
              <div className="relative py-20 bg-yellow-light max-w-[300px] w-full  rounded-tr-[80px] rounded-bl-[80px] text-black linear-gradient">
                <div className="px-[10px] center ">
                  <ul className="list-none card-text text-center min-h-[150px] center relative">
                    <span className="h-[50px] w-[60px] border-t-black border-t-2 border-l-black border-l-2 block absolute -left-2 -top-4"></span>
                    <span className="h-[50px] w-[60px] border-t-black border-t-2 border-l-black border-l-2 block absolute -left-0 -top-6"></span>
                    <span className="h-[50px] w-[60px] border-t-black border-t-2 border-r-black border-r-2 block absolute -right-2 -top-4"></span>
                    <span className="h-[50px] w-[60px] border-t-black border-t-2 border-r-black border-r-2 block absolute -right-0 -top-6"></span>
                    <span className="h-[50px] w-[60px] border-b-black border-b-2 border-l-black border-l-2 block absolute -left-2 -bottom-4"></span>
                    <span className="h-[50px] w-[60px] border-b-black border-b-2 border-l-black border-l-2 block absolute -left-0 -bottom-6"></span>
                    <span className="h-[50px] w-[60px] border-b-black border-b-2 border-r-black border-r-2 block absolute -right-2 -bottom-4"></span>
                    <span className="h-[50px] w-[60px] border-b-black border-b-2 border-r-black border-r-2 block absolute -right-0 -bottom-6"></span>
                    <li className="font-quicksand font-medium ">
                      Complementary <br /> exclusive lead <br /> sponsorship to
                      virtual <br />
                      podcast series
                    </li>
                  </ul>
                </div>
              </div>
              <div className="lg:left-[-28%] absolute lg:top-[25%] top-[-14px] right-[32%]">
                <button className="btn-btn-primary text-[13px] w-[123px]">
                  Complementary
                </button>
              </div>
            </div>
            <div className="py-6">
              <p className="text-[12px] text-gray-light">
                Complementary passes as per the package <br />
                <br /> *Offer price exclusive of taxes & subject to <br />
                package confirmation before December 15th
                <br /> 2022 <br />
                <br /> Package confirmation attracts 50% of the
                <br /> package cost in advance on or before Dec 15,
                <br /> 2022 and rest 50% to be paid before 7th Jan 2023
              </p>
            </div>
          </div>
        </div>
        <span className="">
          <Dots />
        </span>
      </section>

      {/* Section-8*/}
      <section className="pt-[50px] container">
        <div className="flex justify-end">
          {" "}
          <Dots />
        </div>
        <div className="center tablet:flex-row flex-col lg:gap-10 gap-6 lg:pt-[80px] pt-10">
          <div className="center flex-col gap-4">
            <div className="linear-gradient size center">
              <img className="w-[80px]" src="/images/micicone.png" alt="" />
            </div>
            <h3>Speaking opportunities </h3>
            <span className="icon-line"></span>
          </div>
          <div className="center flex-col gap-4">
            <div className="linear-gradient size center">
              <img className="w-[100px]" src="/images/handshake.png" alt="" />
            </div>
            <h3>Event sponsorship </h3>
            <span className="icon-line"></span>
          </div>
          <div className="center flex-col gap-4">
            <div className="linear-gradient size center">
              <img className="w-[80px]" src="/images/newspaper.png" alt="" />
            </div>
            <h3>Media partnership </h3>
            <span className="icon-line"></span>
          </div>
        </div>

        <div className="pt-[20px]">
          {" "}
          <Dots />
        </div>
      </section>

      {/* Section-9*/}
      <section className="pt-[50px] container" id='reserve-seat'>
        <div className="flex justify-end">
          {" "}
          <Dots />
        </div>

        <div className="center tablet:flex-row flex-col lg:gap-[100px] gap-[40px] pt-[88px] ">
          <div className="relative">
            <h5 className="text_horizontal_line absolute top-[-48px] left-1/2 -translate-x-1/2 whitespace-nowrap">
              Reserve your seat
            </h5>
            <div className="lg:h-[380px] lg:w-[380px] h-[280px] w-[280px] linear-gradient rounded-full center flex gap-6">
              <div className="flex flex-col font-bold text-yellow-dark gap-6">
                <span className="lg:text-[30px] text-[20px]">125+</span>
                <span className="lg:text-[30px] text-[20px]">25+</span>
                <span className="lg:text-[30px] text-[20px]">200+</span>
              </div>
              <div className="flex flex-col font-bold text-black uppercase gap-6">
                <span className="lg:text-[30px] text-[20px]">Delegates</span>
                <span className="lg:text-[30px] text-[20px]">speakers</span>
                <span className="lg:text-[30px] text-[20px]">attendees</span>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-[60px] justify-center items-center">
            <div className="flex justify-center">
              <span className="early-line uppercase lg:text-[30px] text-[16px]">
                Early bird offer
              </span>
            </div>
            <div className="flex lg:gap-[50px] gap-[15px]">
              <div className="center flex-col gap-2">
                <h6 className="text-[16px] line-through"> 24,999</h6>
                <div className="lg:w-[120px] lg:h-[120px] h-[100px] w-[100px]">
                  <img
                    className="w-full h-full object-cover"
                    src="/images/colored-chair.png"
                    alt=""
                  />
                </div>
                <button className="price_btn">
                  <span className="pay-now font-inter font-bold">pay Now</span>{" "}
                  14,999
                </button>
                <h6 className="text-[10px]">Dec 01 - 15, 2022</h6>
              </div>
              <div className="center flex-col gap-2">
                <h6 className="text-[16px] line-through1">24,999</h6>
                <div className="lg:w-[120px] lg:h-[120px] h-[100px] w-[100px]">
                  <img
                    className="w-full h-full object-cover"
                    src="/images/chair.png"
                    alt=""
                  />
                </div>
                <button className="price_btn linear-gray">
                  <span className="pay-now font-inter font-bold">pay Now</span>{" "}
                  19,999
                </button>
                <h6 className="text-[10px]">Dec 16 - 31, 2022</h6>
              </div>
              <div className="center flex-col gap-2 pt-6">
                <div className="lg:w-[120px] lg:h-[120px] h-[100px] w-[100px]">
                  <img
                    className="w-full h-full object-cover"
                    src="/images/chair.png"
                    alt=""
                  />
                </div>
                <button className="price_btn  linear-gray">
                  <span className="pay-now font-inter font-bold">pay Now</span>{" "}
                  24,999
                </button>
                <h6 className="text-[10px]">Jan 01 - 17, 2023</h6>
              </div>
            </div>
          </div>
        </div>

        <div className="center w-full lg:pt-[80px] pt-10">
          <div className="flex flex-wrap gap-8 justify-center max-w-[1100px] w-full">
            <div className="flex items-center tablet:justify-center justify-start text-[18px] gap-2">
              <h6 className="lg:text-[16px] text-[14px] font-bold">Contact</h6>
              <span className="flex lg:text-[25px]">
                {" "}
                {icons.right}
                {icons.right}
                {icons.right}
              </span>
            </div>
            <div className="flex items-center  gap-2">
              <div className="bg-gray-dark lg:w-[40px] lg:h-[40px] w-[25px] h-[25px] rounded-full center cursor-pointer">
                {icons.user}
              </div>
              <h6 className="lg:text-[16px] text-[14px] font-bold">
                Ranvijay Singh{" "}
              </h6>
            </div>
            <div className="flex items-center  gap-2">
              <div className="bg-gray-dark lg:w-[40px] lg:h-[40px] w-[25px] h-[25px] rounded-full center cursor-pointer">
                <img
                  className="lg:w-[25px] w-[17px]"
                  src="/images/email.png"
                  alt=""
                />
              </div>
              <h6 className="lg:text-[16px] text-[14px] font-bold">
                hello@inburg.com
              </h6>
            </div>
            <div className="flex items-center gap-2 pt-2 tablet:pt-0 ">
              {" "}
              <div className="bg-gray-dark lg:w-[40px] lg:h-[40px] w-[25px] h-[25px] rounded-[13px] center  cursor-pointer">
                <span className="lg:text-[25px] text-[17px]  text-black">
                  {" "}
                  {icons.whatsapp}
                </span>
              </div>
              <h6 className="lg:text-[16px] text-[14px] font-bold">
                +91 - 9971217695{" "}
              </h6>
            </div>
            <div className="flex items-center gap-2  pt-2 tablet:pt-0 ">
              {" "}
              <div className="bg-[#424949] lg:w-[40px] lg:h-[40px] w-[25px] h-[25px] rounded-full center  cursor-pointer">
                <span className="lg:text-[25px] text-[17px] text-black">
                  {" "}
                  {icons.phone}
                </span>
              </div>
              <h6 className="lg:text-[16px] text-[14px] font-bold">
                +91 - 9971217695{" "}
              </h6>
            </div>
          </div>
        </div>
        <div className="lg:pt-0 pt-6">
          <Dots />
        </div>
        <div className="py-4">
          <Private />
        </div>
      </section>
      </div>
      <RequestAProposal item={{isOpen,onClose,heading}}/>
   </>
  );
};

export default HomePage;
