import { icons } from "@utils/icons";
export const navlinks = [
  {
    link: "Explore",
    dropdown: [
      { path: "explore-collection", link: "Collection" },
      { path: "explore-artist", link: "Artist" },
    ],
  },
  {
    link: "Resources",
    dropdown: [
      { path: "blog", link: "Blog" },
      { path: "help", link: "Help center" },
      { path: "top-nft", link: "Top NFTs" },
    ],
  },
  { path: "/watchlist", link: "Watchlist" },

  {
    link: "Create",
    dropdown: [
      { path: "create-collection", link: "New collection" },
      { path: "create-item", link: "New item" },
    ],
  },
];
export const popularCollection = [
  {
    imgpath: "/images/home/popular-1.png",
    creator: "Ashish Patel",
    price: "4.89",
  },
  {
    imgpath: "/images/home/popular-2.png",
    creator: "Ashish Patel",
    price: "4.89",
  },
  {
    imgpath: "/images/home/popular-3.png",
    creator: "Ashish Patel",
    price: "4.89",
  },
  {
    imgpath: "/images/home/popular-4.png",
    creator: "Ashish Patel",
    price: "4.89",
  },
  {
    imgpath: "/images/home/popular-4.png",
    creator: "Ashish Patel",
    price: "4.89",
  },
  {
    imgpath: "/images/home/popular-4.png",
    creator: "Ashish Patel",
    price: "4.89",
  },
];
export const explorecollection = [
  {
    imgpath: "/images/explore_collection/collection_card1.png",
    creator: "SalvadorDali",
    price: "4.89",
  },
  {
    imgpath: "/images/explore_collection/collection_card2.png",
    creator: "SalvadorDali",
    price: "4.89",
  },
  {
    imgpath: "/images/explore_collection/collection_card3.png",
    creator: "SalvadorDali",
    price: "4.89",
  },
  {
    imgpath: "/images/explore_collection/collection_card4.png",
    creator: "SalvadorDali",
    price: "4.89",
  },
  {
    imgpath: "/images/explore_collection/collection_card1.png",
    creator: "SalvadorDali",
    price: "4.89",
  },
  {
    imgpath: "/images/explore_collection/collection_card2.png",
    creator: "SalvadorDali",
    price: "4.89",
  },
  {
    imgpath: "/images/explore_collection/collection_card3.png",
    creator: "SalvadorDali",
    price: "4.89",
  },
  {
    imgpath: "/images/explore_collection/collection_card4.png",
    creator: "SalvadorDali",
    price: "4.89",
  },
  {
    imgpath: "/images/explore_collection/collection_card1.png",
    creator: "SalvadorDali",
    price: "4.89",
  },
  {
    imgpath: "/images/explore_collection/collection_card2.png",
    creator: "SalvadorDali",
    price: "4.89",
  },
  {
    imgpath: "/images/explore_collection/collection_card3.png",
    creator: "SalvadorDali",
    price: "4.89",
  },
  {
    imgpath: "/images/explore_collection/collection_card4.png",
    creator: "SalvadorDali",
    price: "4.89",
  },
];
export const blogcard = [
  {
    imgpath: "/images/blog/blog_card1.png",
    creator: "SalvadorDali",
    price: "4.89",
  },
  {
    imgpath: "/images/blog/blog_card2.png",
    creator: "SalvadorDali",
    price: "4.89",
  },
  {
    imgpath: "/images/blog/blog_card3.png",
    creator: "SalvadorDali",
    price: "4.89",
  },
  {
    imgpath: "/images/blog/blog_card4.png",
    creator: "SalvadorDali",
    price: "4.89",
  },
  {
    imgpath: "/images/blog/blog_card5.png",
    creator: "SalvadorDali",
    price: "4.89",
  },
  {
    imgpath: "/images/blog/blog_card6.png",
    creator: "SalvadorDali",
    price: "4.89",
  },
  {
    imgpath: "/images/blog/blog_card7.png",
    creator: "SalvadorDali",
    price: "4.89",
  },
];
export const helpcard = [
  {
    imgpath: "/images/help/help_card1.png",
    heading: "Getting Started",
    para: "Everything you need to know about creating an account, setting up your wallet, how to use Artscape effectively to trade your NFT.",
    btn: "See details",
  },
  {
    imgpath: "/images/help/help_card2.png",
    heading: "Buying",
    para: "Learn how to buy your first NFT on Artscape and understand the gas fees. Once you've selected the masterpiece you want to buy, the next step is to make an offer.",
    btn: "See details",
  },
  {
    imgpath: "/images/help/help_card3.png",
    heading: "Selling",
    para: "Once you've linked your wallet, set your profile, made a listing, now it's time to sell. This guide will tell you how to get started.",
    btn: "See details",
  },
  {
    imgpath: "/images/help/help_card4.png",
    heading: "Creating",
    para: "A step-by-step guide on how to set up your first NFT. After editing your profile and setting up the wallet, create your first NFT collection to start selling.",
    btn: "See details",
  },
  {
    imgpath: "/images/help/help_card5.png",
    heading: "FAQ",
    para: "Royalties mean paying the creator a specific percentage of profits or sales in business. In NFT, royalty is a payment that compensates the original NFT creators for using their non-fungible tokens (NFTs).",
    btn: "See details",
  },
  {
    imgpath: "/images/help/help_card6.png",
    heading: "User Safety",
    para: "Know about the safety process and guidelines on Artscape Welcome to Artscape! Here are the terms of services that regulate your access to the Artscape website and any other features or tools in connection with our services",
    btn: "See details",
  },
];
export const categories = [
  { imgpath: "/images/home/art.png", title: "Art" },
  { imgpath: "/images/home/art.png", title: "Photography" },
  { imgpath: "/images/home/art.png", title: "Music" },
  { imgpath: "/images/home/art.png", title: "Sports" },
  { imgpath: "/images/home/art.png", title: "Collectibles" },
  { imgpath: "/images/home/art.png", title: "Dancing" },
];
export const homeblogcard = [
  {
    imgpath: "/images/blog/blog_card1.png",
    creator: "SalvadorDali",
    price: "4.89",
  },
  {
    imgpath: "/images/blog/blog_card2.png",
    creator: "SalvadorDali",
    price: "4.89",
  },
  {
    imgpath: "/images/blog/blog_card3.png",
    creator: "SalvadorDali",
    price: "4.89",
  },
];
export const home = [
  {
    imgpath: "/images/canva-images/logo1.png",
  },
  {
    imgpath: "/images/canva-images/logo2.png",
  },
  {
    imgpath: "/images/canva-images/logo3.png",
  },
  {
    imgpath: "/images/canva-images/logo4.png",
  },
  {
    imgpath: "/images/canva-images/logo5.png",
  },
  {
    imgpath: "/images/canva-images/logo6.png",
  },
  {
    imgpath: "/images/canva-images/logo7.png",
  },
  {
    imgpath: "/images/canva-images/logo8.png",
  },
  {
    imgpath: "/images/canva-images/logo9.png",
  },
  {
    imgpath: "/images/canva-images/logo10.png",
  },
  {
    imgpath: "/images/canva-images/logo11.png",
  },
  {
    imgpath: "/images/canva-images/logo12.png",
  },
  {
    imgpath: "/images/canva-images/logo13.png",
  },
  {
    imgpath: "/images/canva-images/logo14.png",
  },
  {
    imgpath: "/images/canva-images/logo15.png",
  },
  {
    imgpath: "/images/canva-images/logo16.png",
  },
  {
    imgpath: "/images/canva-images/logo17.png",
  },
  {
    imgpath: "/images/canva-images/logo18.png",
  },
  {
    imgpath: "/images/canva-images/logo20.png",
  },
];
export const agenda = [
  {
    time: "09:00 AM - 10:00 AM",
    event: "Event registration & Tea",
  },
  {
    time: "10:00 AM - 10:30 AM",
    event: "Inaugural ceremony & keynote address",
    para: "Bounce Back Retail  - The viable path to be $1.8 trillion Industry",
  },
  {
    time: "10:35 AM - 11:15 AM",
    event: "Panel Discussion 1",
    para: "What's Next? - Learning from unprecedented times & the future of retail",
  },
  {
    time: "11:20 AM - 11:45 AM",
    event: "Presentation (Lead sponsor slot)",
  },
  {
    time: "11:50 AM - 12:30 PM",
    event: "Panel Discussion 2",
    para: "Digitizing Retail - The role of the ONDC in reimagining future of digital commerce",
  },
  {
    time: "12:35 PM - 01:00 PM ",
    event: "Fire-side chat",
    para: "Marketing 2023 & beyond - Re-thinking retail marketing",
  },
  {
    time: "01:00 PM - 01:30 PM",
    event: "Networking Lunch",
  },
  {
    time: "01:35 PM - 02:00 PM ",
    event: "Keynote address",
    para: "The fiscal support system - Fueling retail growth with financial assistance",
  },
  {
    time: "02:05 PM - 02:45 PM ",
    event: "Panel Discussion 3",
    para: "The Tiered approach - Expanding to regions with higher consumption volume",
  },
  {
    time: "02:45 PM - 03:00 PM ",
    event: "Tea Break",
  },
  {
    time: "03:00 PM - 03:25 PM",
    event: "Fire-side chat",
    para: "Redefining Retail - Think Retail Think Omni-chann",
  },
  {
    time: "03:30 PM - 04:10 PM",
    event: "Panel Discussion 4",
    para: "Investors Lens - What makes retail industry a good sector to invest in?",
  },
  {
    time: "04:15 PM - 04:40 PM",
    event: "Fire--side chat",
    para: "The indispensable key - Fostering secure, timely and strong payment infrastructure",
  },
  {
    time: "04:45 PM - 05:00 PM",
    event: "Closing Keynote",
  },
];
