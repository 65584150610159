import React from "react";

const Private = () => {
  return (
    <div>
      <div className="  flex justify-center items-center flex-col pt-[20px] lg:pt-0">
        <div className="max-w-[450px] border-t-2 border-yellow-dark flex flex-col justify-center items-center p-3 space-y-[2px]">
          <h6 className="text-[11px]">Inburg India Private Limited </h6>
          <h6 className="text-[7px]">
            {" "}
            Room 203, 2-A/3, S/F Front Side, Asaf Ali Road, Old Turkman Gate
            Delhi, New Delhi- 1100
          </h6>
        </div>
      </div>
    </div>
  );
};

export default Private;
