import React from "react";

const Retail = () => {
  return (
    <div className="flex w-fit relative">
      <div className="flex items-start relative z-[1]">
        <img className="w-[100px]" src="/images/inburg-logo.png" alt="" />
      </div>
      <h3 className="relative z-[1]">
        <span className="text-yellow-light text-[24px]"> RETAIL </span>{" "}
        ECOSYSTEM <br />
        INDIA <span className="text-[13px] text-gray-light">Outlook 2023</span>
      </h3>
      <img
        className="w-[100px] lg:w-[130px] ml-3 -mt-5 self-center tablet:relative absolute -right-10 z-[0] tablet:right-0"
        src="/images/gradient-img.png"
        alt=""
      />
    </div>
  );
};

export default Retail;
