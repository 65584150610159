import React from "react";

const Dots = () => {
  return (
    <div>
      <div className="flex flex-wrap w-[68px] gap-[4px]">
        {Array(18)
          .fill("2")
          .map((item, index) => (
            <div className="h-[8px] w-[8px] border-2 border-red-dark rounded-full"></div>
          ))}
      </div>
    </div>
  );
};

export default Dots;
